export const hero = {
  title: "Smart business POS systems to speed up professional facilities",
  subTitle: "Professional Facilities",
  subtext:
    "Consolidate your sales and inventory data into a centralized, enterprise cloud POS and management platform.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/professional-facilities/demo",
  },
};

export const image = {
  imageName: "facilities-hero.png",
  mobileImageName: "facilities-hero-mobile.png",
  title: "PROFESSIONAL FACILITIES",
};

export const highlightsSection = {
  title: "Make peak rushes more manageable",
  imageName: "facilities-highlights.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/professional-facilities/demo",
  },
  highlights: [
    {
      title: "Faster checkout \n lines",
      subtext:
        "Create faster and more efficient ordering and payment experiences with easy-to-use interfaces and smart upsell, loyalty, and discount features.",
    },
    {
      title: "Order ahead \n technology",
      subtext:
        "Give your guests or team the ability to order ahead with advanced ordering technology integrated with your existing POS platform.",
    },
    {
      title: "Reallocate \n staff",
      subtext:
        "Take advantage of self-service kiosks for grab and go concepts or sending requests to the kitchen so your staff can focus on more strategic initiatives.",
    },
  ],
};

export const highlightsHero = {
  image: {
    imageName: "facilities-highlights-2.png",
    mobileImageName: "facilities-highlights-2-mobile.png",
    title: "World-class technology and service",
    subtext: "ENTERPRISE SOLUTIONS",
  },
  title: "Take action by knowing what your customers want",
  highlights: [
    {
      title: "Always have \n inventory",
      subtext:
        "Track inventory in real time and set automatic re-order alerts to prevent food spoilage and make sure you never run out of popular items.",
    },
    {
      title: "Never miss \n a sale",
      subtext:
        "Keep your business running no matter what with a proprietary offline mode that automatically switches devices during connectivity issues.",
    },
    {
      title: "More actionable \n insights",
      subtext:
        "Save hours of labor with instant business insights on sales, productivity, and profitability that can be exported to your preferred format.",
    },
  ],
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/professional-facilities/demo",
  },
};

export const partners = {
  title: "Partnered with the leaders in your industry",
  desktopImageName: "appetize-facilities-partners-desktop.png",
  mobileImageName: "appetize-facilities-partners-mobile.png",
};

export const heroQuote = {
  title: "Delivering speed and reliability to professional facilities",
  quote: `“We were able to see our orders on the KDS, have them push to completion and then have them show up on the order status board with our names. Our team has deemed this was a successful test of the mobile ordering application.”`,
  personName: "Ryan Patrick Keegan",
  personPosition: "Senior Business Analyst, Aramark",
  imageName: "facilities-hero-quote.png",
  mobileImageName: "facilities-hero-quote-mobile.png",
};

export const productsWithLinks = {
  title:
    "Built on a true omnichannel platform to support the evolution of professional facilities",
  subtext:
    "Upgrade your business POS systems to meet the levels of convenience and speed guests expect from today’s professional enterprises. Increase revenue, consolidate data, and access crucial operational insight with a smart, centralized POS platform.",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform your guest experience, provide valuable business insights, and deliver ROI to your facility.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/professional-facilities/demo",
  },
};
